import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CategoriesService } from '../../categories/categories.service';
import { BusinessUsersService } from '../business-users.service';
import { ToastrService } from 'ngx-toastr';
const URL = environment.API_URL

@Component({
  selector: 'app-editbusiness',
  templateUrl: './editbusiness.component.html',
  styleUrls: ['./editbusiness.component.css']
})
export class EditBusinessComponent implements OnInit {
  businessForm: FormGroup;
  submitted = false;
  @Input() business;
  categoryList: Array<any>;

  constructor(public activeModal: NgbActiveModal,
    private service: CategoriesService,
    private businessService: BusinessUsersService,
    private _formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private http: HttpClient) { }

  ngOnInit(): void {

    this.buildBusinessForm();
    if (this.business)
      this.businessForm.patchValue({
        "name": this.business.name,
        "email": this.business.email,
        "address": this.business.address,
        "website": this.business.website,
        "contactNo": this.business.contactNo,
        "description": this.business.description,
        "zipcode": this.business.zipcode,
        "category": this.business.category,
      })
    this.getallcategories()
  }
  getallcategories() {
    this.spinner.show();
    this.service.getlist().subscribe((response: any) => {
      this.categoryList = response.data;
      this.spinner.hide();
    }, error => {
    });
  }
  get f() {
    return this.businessForm.controls;
  }


  buildBusinessForm() {

    this.businessForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      email: [''],
      address: [''],
      website: [''],
      contactNo: [''],
      description: [''],
      zipcode: [''],
      category: ['']
    });

  }
  editBusiness() {
    this.submitted = true;
    if (this.businessForm.invalid) {
      return;
    }
    else {
      console.log(this.businessForm.value)
      this.spinner.show();
      var addObj = this.businessForm.value;
      this.activeModal.dismiss();
      this.businessService.businessUpdate(this.business._id, addObj).subscribe((response: any) => {
        if (response.code == 200) {
          this.spinner.hide();
          this.getAllBusiness()
          // this.toastService.successToastr(response.message);
        }
        else {
          // this.toastService.errorToastr(response.message);
        }
      })
    }
  }
  getAllBusiness() {
    this.businessService.getbusinesslist().subscribe((response: any) => {
      this.businessService.setBusiness(response);
    })
  }


  createBusiness() {
    // const obj = {
    //   address: "67 Gateway Road,Portland",
    //   businessHours: 7,
    //   category: "Health",
    //   closeTime: 0.75,
    //   contactNo: "7911 123456",
    //   description: "Good health is the state of general well-being, where disease and illness are absent. It is also a condition where there is good mental, physical and social health.",
    //   email: "Health1@ble.com",
    //   name: "BLE Health",
    //   openTime: 0.375,
    //   typeOfBusiness: "Online",
    //   website: "www.blehealth.com",
    //   zipcode: 97201,
    // }
    this.submitted = true;
    if (this.businessForm.invalid) {
      return;
    }
    else {
      var addObj = this.businessForm.value;
      const arraylist = [addObj]
      this.spinner.show();
      return this.businessService.addbusinesses(arraylist).subscribe((response: any) => {
        if (response.code == 400) {
          this.toastService.error(response.message + response.data);
          this.spinner.hide();
          this.activeModal.dismiss();
          this.getAllBusiness()

        }
        else if (response.code == 500) {
          this.toastService.error(response.message);
          this.spinner.hide();
          this.activeModal.dismiss();
          this.getAllBusiness()

        } else if (response.code == 200) {
          this.toastService.success(response.message);
          this.spinner.hide();
          this.activeModal.dismiss();
          this.getAllBusiness()

        }
        // this.spinner.hide();
        // this.getbusinesslist()
      })
    }
  }

}
